import { templateTypes } from '../../constants/types';
import Vue from 'vue';

const { setTemplates } = templateTypes.actions;

export const mutations = {
  [setTemplates]: (state, { response }) => {
    const templates = response.data.value.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    Vue.set(state, 'templates', templates);
  }
};
